@import '../utilities/variables';

.customOverride {
  max-width: 100% !important;
}

.customerInformation {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    padding: 24px 37px;
  }
}
.header {
  padding: 0;
  padding-bottom: 0.6rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}

.paymentHeader {
  border-bottom: 1px solid;
  border-color: #c0c7d1;
  padding: 0;
  padding-bottom: 0.6rem;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding: 1.5rem;
  }
}


.loading {
  position: relative;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-top: 50%;
}

.overviewCaption {
    text-align: left; /* Center the caption and description */
    margin-bottom: 20px; /* Add spacing below the caption */
    margin-left: 5%;
  }
  
.overviewCaption h5 {
font-size: 20px; /* Slightly smaller than the main header */
font-weight: bold;
margin: 10px 0; /* Add spacing above and below */
}
  
.overviewCaption p {
font-size: 16px; /* Standard readable font size */
color: #666; /* Subtle gray text */
margin: 0; /* Remove any default margin */
}

.gridBody {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: #ffffff;
  }

.gridContainer {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    grid-template-rows: auto auto; /* Two rows with auto height */
    gap: 1px; /* Thin line between items */
    background-color: #c0c7d1; /* Line color */
    width: 90%;
  }

.gridContainer2 {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr; /* Two columns of equal width */
  grid-template-rows: auto auto; /* Two rows with auto height */
  gap: 1px; /* Thin line between items */
  background-color: #ffffff; /* Line color */
  width: 90%;
}
  
  .gridItem {
    background-color: white; /* Background of each grid cell */
    padding: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #000000; /* Text color */
    border: 1px solid transparent; /* Optional: add a subtle border inside cells */
  }
  .metricLabel {
    display: inline-block; /* Keep the label inline with the number */
    font-weight: bold; /* Highlight the label */
  }
  
  .metricChange {
    margin-top: 8px;
    font-size: 14px;
    color: green;
  }
  .filterContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 auto;
  }
  .filterContainer > * {
    width: 100%;
  }

.analyticsDashboard {
  display: flex;
  gap: 20px;
  justify-content: center;
}
  
.chartContainer {
  width: 50%;

  h3 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
  }
}
  
.chartContainer .recharts-wrapper {
  margin: 0 auto;
}

.barChartContainer {
  width: 70%;

  h3 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.2rem;
    color: #333;
  }
}
  
.barChartContainer .recharts-wrapper {
  margin: 0 auto;
}

.content {
  padding-left: $container-padding-lg;
  flex-direction: row;
  display: flex;
  gap: 2em;
  margin-bottom: 10px;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    padding-left: 0px;
  }
}
.downloadButton2 {
  margin-right: auto;
  min-width: 8.75rem;
}
  