@import '../utilities/variables';

.body {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }
}

.selectorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 25.75rem;
  position: relative;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    align-items: flex-start;
    max-width: 100%;
  }
}

.selectorHeader {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 0.625rem 1.5rem;

  @media only screen and (min-width: #{$grid__bp-lg}px) {
    display: none;
  }
}

.openHeader {
  background-color: $mono-white;
  border-bottom: 1px solid $mono-default-light;
  box-shadow: 0px -25px 37px 2px rgba(0, 0, 0, 0.16);
  z-index: 3;
}

.selectorOpener {
  width: 100%;
  border: none;
  background-color: inherit;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.settingSectionSelector {
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    display: none;
  }
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.open {
  display: flex;
  flex-direction: column;
  background-color: $mono-white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  width: 100%;

  @media only screen and (max-width: #{$grid__bp-lg}px) {
    position: absolute;
    top: 100%;
    width: 100%;
    height: 100rem;
    background-color: rgba(69, 69, 69, 0.8);
    z-index: 1;
  }
}

.openArrow {
  transform: rotate(180deg);
}

.items {
  width: 100%;
  background-color: $mono-white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  width: 100%;
}

.item {
  width: 100%;
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    border-bottom: 1px solid $mono-default-light;
  }
}

.itemButton {
  width: 100%;
  border: none;
  background-color: inherit;
  cursor: pointer;
  padding: 0.625rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Montserrat';
  font-size: 20px;
  line-height: 32px;
  font-style: normal;
  font-weight: 400;
  color: black;
  &:hover {
    background-color: $blue-lightest;
  }
}

.itemSelected {
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    background-color: $blue-lightest;
    font-weight: 700;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    color: $blue-darkest;
  }
}

.settingSection {
  max-width: 53.75rem;
  margin-left: 2.5rem;
  background-color: $mono-white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  @media only screen and (min-width: #{$grid__bp-lg}px) {
    min-height: 70vh;
  }
  @media only screen and (max-width: #{$grid__bp-lg}px) {
    margin: 0;
    max-width: 100%;
    padding: 0;
  }
}

.customMaxWidth {
  max-width: 100% !important;
}
